<template>
  <div ref="container" :style="containerStyleBind" />
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted } from 'vue'
import { colorToLottie } from '../../libs/colors'
import lottie from 'lottie-web'

export default defineComponent({
  name: 'FlamingoLoader',
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    size: {
      type: [String, Number],
      default: 64,
    },
  },
  setup(props) {
    const regex = /^-?\d*\.?\d+$/
    const color = computed(() => props.color)
    const lottieColor = computed(() => colorToLottie(color.value))
    const animationData = computed(() => {
      return {
        v: '5.9.0',
        fr: 25,
        ip: 0,
        op: 55,
        w: 200,
        h: 200,
        nm: 'swan 2',
        ddd: 0,
        assets: [
          {
            id: 'comp_0',
            nm: 'swan',
            fr: 25,
            layers: [
              {
                ddd: 0,
                ind: 1,
                ty: 4,
                nm: 'Layer 1 Outlines 2',
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [297.638, 420.945, 0], ix: 2, l: 2 },
                  a: { a: 0, k: [297.638, 420.945, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: 'gr',
                    it: [
                      {
                        ind: 0,
                        ty: 'sh',
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [3.859, 1.881],
                              [4.238, 0.679],
                              [2.779, -1.481],
                              [0.263, -1.182],
                              [-1.111, -0.634],
                              [-1.259, 0.227],
                              [-1.683, 3.548],
                              [0.501, 4.51],
                              [-1.094, 21.715],
                              [-1.87, 1.604],
                              [-1.498, -1.895],
                              [2.098, -1.197],
                              [0.051, 3.243],
                              [0.997, 12.637],
                              [9.879, 0.706],
                              [8.699, 4.349],
                              [4.893, 5.437],
                              [1.631, 3.262],
                              [-1.631, 7.068],
                              [9.642, 24.106],
                            ],
                            o: [
                              [-3.903, 1.787],
                              [-3.859, -1.881],
                              [-3.11, -0.497],
                              [-1.068, 0.569],
                              [-0.279, 1.249],
                              [1.112, 0.634],
                              [3.865, -0.694],
                              [1.934, -4.079],
                              [-3.075, -21.531],
                              [0.124, -2.461],
                              [1.834, -1.573],
                              [1.498, 1.896],
                              [-7.461, 4.256],
                              [-0.198, -12.584],
                              [-0.672, -8.52],
                              [-9.243, -0.543],
                              [-6.524, -3.262],
                              [-2.718, -2.718],
                              [-2.174, -6.524],
                              [5.437, -23.922],
                              [0, 0],
                            ],
                            v: [
                              [44.435, 145.969],
                              [32.06, 144.582],
                              [20.275, 139.537],
                              [11.119, 141.066],
                              [8.713, 143.63],
                              [10.323, 146.833],
                              [14.056, 147.29],
                              [22.699, 139.95],
                              [25.155, 125.96],
                              [21.329, 44.558],
                              [23.624, 37.756],
                              [30.089, 38.319],
                              [28.932, 44.6],
                              [23.034, 29.666],
                              [21.369, -8.481],
                              [7.214, -27.419],
                              [-19.97, -33.399],
                              [-36.824, -45.904],
                              [-42.261, -55.69],
                              [-42.261, -76.35],
                              [-35.592, -147.756],
                            ],
                            c: false,
                          },
                          ix: 2,
                        },
                        nm: 'Path 1',
                        mn: 'ADBE Vector Shape - Group',
                        hd: false,
                      },
                      {
                        ty: 'st',
                        c: { a: 0, k: lottieColor.value, ix: 3 },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 3, ix: 5 },
                        lc: 2,
                        lj: 2,
                        bm: 0,
                        nm: 'Stroke 1',
                        mn: 'ADBE Vector Graphic - Stroke',
                        hd: false,
                      },
                      {
                        ty: 'tr',
                        p: { a: 0, k: [281.385, 439.809], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: 'Transform',
                      },
                    ],
                    nm: 'Group 1',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                  },
                  {
                    ty: 'tm',
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.833], y: [0.833] },
                          o: { x: [0.167], y: [0.167] },
                          t: 23,
                          s: [100],
                        },
                        { t: 50, s: [0] },
                      ],
                      ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                  },
                ],
                ip: 0,
                op: 175,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 2,
                ty: 4,
                nm: 'Layer 1 Outlines',
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: { a: 0, k: [297.638, 420.945, 0], ix: 2, l: 2 },
                  a: { a: 0, k: [297.638, 420.945, 0], ix: 1, l: 2 },
                  s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                },
                ao: 0,
                shapes: [
                  {
                    ty: 'gr',
                    it: [
                      {
                        ind: 0,
                        ty: 'sh',
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [3.776, -5.081],
                              [5.976, -2.086],
                              [7.459, -3.465],
                              [1.565, -2.807],
                              [0.339, -7.082],
                              [-5.498, 5.367],
                              [-0.275, 1.151],
                              [0.627, 1.47],
                              [-15.058, -5.26],
                              [-1.185, -8.489],
                              [2.298, -11.35],
                              [1.043, -11.777],
                              [-11.324, 0.216],
                              [-4.428, 6.992],
                              [-18.267, -5.343],
                              [-7.919, -18.414],
                              [-2.739, -5.846],
                              [0.058, -1.619],
                              [6.556, -2.585],
                              [7.225, -6.045],
                              [4.374, -1.411],
                              [3.268, 2.189],
                              [-0.967, 2.314],
                              [-0.442, 0.352],
                              [-1.5, -1.547],
                              [-1.91, -3.005],
                              [-7.28, -8.085],
                              [-4.099, 9.333],
                              [0.83, 0.798],
                              [1.418, -0.607],
                              [1.402, -1.242],
                              [19.303, -17.102],
                              [0.799, -3.472],
                              [-3.557, 0.22],
                            ],
                            o: [
                              [2.246, 5.918],
                              [-3.775, 5.081],
                              [-8.044, 2.808],
                              [-2.915, 1.353],
                              [-3.454, 6.193],
                              [3.215, -6.978],
                              [0.847, -0.827],
                              [0.372, -1.555],
                              [-5.672, -13.288],
                              [8.92, 3.115],
                              [1.62, 11.605],
                              [-2.341, 11.563],
                              [-0.741, 8.363],
                              [9.923, -0.189],
                              [11.177, -17.648],
                              [18.893, 5.526],
                              [2.551, 5.931],
                              [0.687, 1.467],
                              [-4.936, -3.728],
                              [-8.954, 3.531],
                              [-3.512, 2.939],
                              [-3.203, 1.034],
                              [-2.084, -1.396],
                              [0.218, -0.521],
                              [1.687, -1.34],
                              [2.478, 2.556],
                              [5.84, 9.187],
                              [2.7, 2.999],
                              [0.463, -1.054],
                              [-1.264, -1.213],
                              [-1.722, 0.737],
                              [-19.304, 17.102],
                              [-2.667, 2.363],
                              [-0.798, 3.472],
                              [0, 0],
                            ],
                            v: [
                              [-31.367, -134.758],
                              [-34.493, -116.825],
                              [-49.935, -106.266],
                              [-75.188, -104.231],
                              [-81.664, -97.309],
                              [-87.456, -77.036],
                              [-71.766, -93.685],
                              [-69.794, -96.58],
                              [-70.801, -101.178],
                              [-48.867, -127.208],
                              [-34.976, -105.029],
                              [-38.081, -70.363],
                              [-44.202, -35.481],
                              [-33.007, -15.429],
                              [-14.128, -35.934],
                              [37.131, -49.261],
                              [79.133, -14.185],
                              [85.982, 3.882],
                              [87.398, 8.48],
                              [67.589, 1.507],
                              [44.679, 18.645],
                              [32.925, 25.44],
                              [22.253, 27.216],
                              [20.251, 20.567],
                              [21.218, 19.198],
                              [26.879, 20.768],
                              [33.107, 29.42],
                              [52.971, 55.263],
                              [73.661, 67.027],
                              [73.096, 63.833],
                              [67.815, 63.929],
                              [63.257, 67.171],
                              [5.347, 118.477],
                              [-0.928, 126.906],
                              [4.222, 134.538],
                            ],
                            c: false,
                          },
                          ix: 2,
                        },
                        nm: 'Path 1',
                        mn: 'ADBE Vector Shape - Group',
                        hd: false,
                      },
                      {
                        ty: 'st',
                        c: { a: 0, k: lottieColor.value, ix: 3 },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 3, ix: 5 },
                        lc: 2,
                        lj: 2,
                        bm: 0,
                        nm: 'Stroke 1',
                        mn: 'ADBE Vector Graphic - Stroke',
                        hd: false,
                      },
                      {
                        ty: 'tr',
                        p: { a: 0, k: [298.006, 389.154], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: 'Transform',
                      },
                    ],
                    nm: 'Group 2',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                  },
                  {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.833], y: [0.833] },
                          o: { x: [0.167], y: [0.167] },
                          t: 0,
                          s: [0],
                        },
                        { t: 23, s: [100] },
                      ],
                      ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                  },
                ],
                ip: 0,
                op: 175,
                st: 0,
                bm: 0,
              },
            ],
          },
        ],
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 0,
            nm: 'swan',
            refId: 'comp_0',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [100, 100, 0], ix: 2, l: 2 },
              a: { a: 0, k: [298, 421, 0], ix: 1, l: 2 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                    t: 0,
                    s: [54, 54, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                    t: 26,
                    s: [48, 48, 100],
                  },
                  { t: 52, s: [54, 54, 100] },
                ],
                ix: 6,
                l: 2,
              },
            },
            ao: 0,
            w: 596,
            h: 842,
            ip: 0,
            op: 175,
            st: 0,
            bm: 0,
          },
        ],
        markers: [],
      }
    })
    const size = computed(() => props.size)
    const cssSize = computed(() => {
      if (regex.test(size.value.toString())) {
        return `${size.value}px`
      }
      return size.value
    })
    const containerStyleBind = computed(() => ({
      width: cssSize.value,
      height: cssSize.value,
    }))
    const container = ref<HTMLElement | undefined>(undefined)
    onMounted(() => {
      if (container.value) {
        lottie.loadAnimation({
          container: container.value,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: animationData.value,
        })
      }
    })
    return {
      containerStyleBind,
      container,
      lottieColor,
    }
  },
})
</script>
